<!-- ~/src/components/Preview.vue -->
<template>
  <div class="preview mb-4">
    <video
      ref="previewVideo"
      :src="recordedVideoUrl"
      controls
      playsinline
      width="100%"
    ></video>
    <v-row class="mt-2">
      <v-col>
        <v-btn color="primary" @click="reRecord" block class="custom-btn">
          Re-record
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          @click="uploadVideo"
          :disabled="isUploading"
          color="success"
          block
          class="custom-btn"
        >
          {{ isUploading ? "Uploading..." : "Upload Video" }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>{{ progressTracker }}</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    recordedVideoUrl: String,
    isUploading: Boolean,
    progressTracker: String,
  },
  methods: {
    reRecord() {
      this.$emit("re-record");
    },
    uploadVideo() {
      this.$emit("upload-video");
    },
  },
};
</script>

<style scoped>
.preview {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  video {
    background-color: #000;
  }
}
</style>
