import { render, staticRenderFns } from "./RecordingControls.vue?vue&type=template&id=240b3a48&scoped=true"
import script from "./RecordingControls.vue?vue&type=script&lang=js"
export * from "./RecordingControls.vue?vue&type=script&lang=js"
import style0 from "./RecordingControls.vue?vue&type=style&index=0&id=240b3a48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "240b3a48",
  null
  
)

export default component.exports