<template>
  <v-container
    fluid
    fill-height
    class="recording-container"
    :style="'background: ' + sectionBgColor"
  >
    <v-card class="mx-auto rounded-xl pa-4 mt-5 custom-card" max-width="600">
      <v-card-title class="justify-center">
        <div class="center-logo">
          <router-link to="/">
            <v-img
              src="@/assets/logo.png"
              class="logo-size"
              alt="Recording App Logo"
              contain
            ></v-img>
          </router-link>
        </div>
      </v-card-title>
      <v-card-text>
        <h1 class="text-center mb-7 recording-title">Start Your Recording</h1>

        <RecordingOptions
          v-if="!isRecording && !isPreviewMode"
          :recording-type="recordingType"
          @start-recording="startRecordingAndEnterPiP"
          @update:recordingType="updateRecordingType"
        />

        <RecordingControls
          v-if="isRecording"
          :is-paused="isPaused"
          :formatted-recording-time="formattedRecordingTime"
          @toggle-pause="togglePause"
          @stop-recording="stopRecording"
        />

        <Preview
          v-if="isPreviewMode"
          :recorded-video-url="recordedVideoUrl"
          :is-uploading="isUploading"
          :progress-tracker="progressTracker"
          @re-record="reRecord"
          @upload-video="uploadVideo"
        />

        <div class="mt-10">
          <strong class="capitalize error--text">protect your privacy</strong>
          <small class="d-block" :style="'color: ' + headingTextColor">
            Before you start recording, you'll be prompted which screen or
            window you would like to record. You can preview your recording on
            this page before you decide to upload it.
          </small>
        </div>
      </v-card-text>
    </v-card>

    <CountdownOverlay
      :is-counting-down="isCountingDown"
      :countdown-value="countdownValue"
    />

    <div
      v-if="isRecording && recordingType === 'camera'"
      class="camera-preview"
    >
      <video ref="cameraPreview" autoplay muted></video>
    </div>
  </v-container>
</template>

<script>
import RecordRTC from "recordrtc";
import Resumable from "resumablejs";
import ResponseHelper from "../../utils/ResponseHelper";
import RecordingOptions from "../../components/Rec/RecordingOptions.vue";
import RecordingControls from "../../components/Rec/RecordingControls.vue";
import Preview from "../../components/Rec/Preview.vue";
import CountdownOverlay from "../../components/Rec/CountdownOverlay.vue";

export default {
  components: {
    RecordingOptions,
    RecordingControls,
    Preview,
    CountdownOverlay,
  },
  name: "RecordingView",
  data() {
    return {
      toast: new ResponseHelper(),
      recordingType: "screen",
      videoQuality: 8000000,
      microphoneEnabled: true,
      isRecording: false,
      isPreviewMode: false,
      isCountingDown: false,
      countdownValue: 3,
      recorder: null,
      recordedVideo: null,
      recordedVideoUrl: null,
      videoQualityOptions: [
        { text: "SD (Standard Definition) 360p", value: 1000000 },
        { text: "FSD (Full Standard Definition) 480p", value: 2500000 },
        { text: "HD (High Definition) 720p", value: 5000000 },
        { text: "FHD (Full High Definition) 1080p", value: 8000000 },
        { text: "2k Video (Quad HD)", value: 16000000 },
        { text: "4k Video (UHD)", value: 40000000 },
      ],
      cameraStream: null,
      screenStream: null,
      audioStream: null,
      combinedStream: null,
      isPaused: false,
      pipVideo: null,
      miniPreview: null,
      miniPreviewVideo: null,
      miniCameraStream: null,
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      isPiPSupported: false,
      recordingStartTime: null,
      recordingTimer: null,
      totalRecordingTime: 0,
      pausedTime: 0,
      lastPauseTime: null,
      progressTracker: null,
      uploadToken: null,
      isGeneratingToken: false,
      isUploading: false,
      form: {},
      video_name: "",
    };
  },
  computed: {
    formattedRecordingTime() {
      const totalSeconds = Math.floor(this.totalRecordingTime / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    sectionBgColor() {
      return "#E9F0FA"; //"#5A67FB";
    },
    headingTextColor() {
      return "#4b4b4b";
    },
  },
  mounted() {
    // Check PiP support - explicitly checking for Firefox
    this.isPiPSupported =
      document.pictureInPictureEnabled &&
      !navigator.userAgent.toLowerCase().includes("firefox");

    // Add beforeunload event listener
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    updateRecordingType(newType) {
      this.recordingType = newType;
    },
    async initializeRecording() {
      try {
        // If microphone is enabled, capture audio stream
        if (this.microphoneEnabled) {
          this.audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true,
            },
          });
        }

        // If screen or screenCam is selected, capture screen stream
        if (
          this.recordingType === "screen" ||
          this.recordingType === "screenCam"
        ) {
          const displayMediaOptions = {
            video: {
              width: { ideal: this.getResolutionWidth() },
              height: { ideal: this.getResolutionHeight() },
              frameRate: { ideal: 30 },
            },
            audio: !navigator.userAgent.toLowerCase().includes("firefox"), // Firefox does not support system audio
          };

          this.screenStream = await navigator.mediaDevices.getDisplayMedia(
            displayMediaOptions
          );

          // Handle screen sharing stop
          this.screenStream
            .getVideoTracks()[0]
            .addEventListener("ended", () => {
              this.stopRecording();
            });
        }

        // If camera or screenCam is selected, capture camera stream
        if (
          this.recordingType === "camera" ||
          this.recordingType === "screenCam"
        ) {
          this.cameraStream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
        }

        // ++
        // CAMERA MODE HERE

        // Combine tracks if multiple streams are used
        const tracks = this.getCombinedTracks();
        this.combinedStream = new MediaStream(tracks);

        // Start countdown or recording
        this.startCountdown();
      } catch (error) {
        console.error("Error initializing recording:", error);
        this.toast.staticError(
          "Failed to initialize recording. Please check your permissions and try again."
        );
      }
    },

    async startRecordingAndEnterPiP() {
      try {
        // Only attempt PiP if the recording type is "screenCam"
        if (this.recordingType === "screenCam") {
          // Attempt to enter PiP mode immediately after user gesture
          await this.attemptEnterPiP();
        }

        // Initialize recording (get streams)
        await this.initializeRecording();

        // Start recording
        this.startRecording();
      } catch (error) {
        console.error(
          "Failed to start recording or enter Picture-in-Picture mode:",
          error
        );
        await this.setupMiniPreview();
      }
    },

    async attemptEnterPiP() {
      // Check if PiP is supported
      if (!document.pictureInPictureEnabled) {
        console.warn("Picture-in-Picture is not supported in this browser.");
        await this.setupMiniPreview();
        return;
      }

      // Request camera access for the mirror preview
      try {
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const pipVideo = document.createElement("video");
        pipVideo.srcObject = cameraStream;
        pipVideo.muted = true;
        pipVideo.style.display = "none";
        document.body.appendChild(pipVideo);

        // Ensure the video starts playing before requesting PiP
        await pipVideo.play();

        // Attempt to enter PiP mode
        await pipVideo.requestPictureInPicture();
        this.isPipActive = true;

        // Clean up PiP video when it exits
        pipVideo.addEventListener("leavepictureinpicture", () => {
          this.isPipActive = false;
          document.body.removeChild(pipVideo);
          cameraStream.getTracks().forEach((track) => track.stop()); // Stop the camera stream
        });
      } catch (error) {
        console.error("Failed to enter Picture-in-Picture mode:", error);
        await this.setupMiniPreview();
      }
    },

    getCombinedTracks() {
      const tracks = [];

      if (this.recordingType === "camera") {
        tracks.push(...this.cameraStream.getVideoTracks());
      } else if (this.recordingType === "screen") {
        tracks.push(...this.screenStream.getVideoTracks());
      } else if (this.recordingType === "screenCam") {
        tracks.push(...this.screenStream.getVideoTracks());
      }

      if (this.microphoneEnabled && this.audioStream) {
        tracks.push(...this.audioStream.getAudioTracks());
      }

      const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
      if (
        (this.recordingType === "screen" ||
          this.recordingType === "screenCam") &&
        !isFirefox &&
        this.screenStream.getAudioTracks().length > 0
      ) {
        tracks.push(...this.screenStream.getAudioTracks());
      }

      return tracks;
    },

    startCountdown() {
      this.isCountingDown = true;
      this.countdownValue = 3;
      const countdownInterval = setInterval(() => {
        this.countdownValue--;
        if (this.countdownValue === 0) {
          clearInterval(countdownInterval);
          this.isCountingDown = false;
          this.startRecording();
        }
      }, 1000);
    },

    startRecording() {
      // Setup preview for camera mode
      if (this.recordingType === "camera") {
        this.$nextTick(() => {
          if (this.$refs.cameraPreview) {
            this.$refs.cameraPreview.srcObject = this.cameraStream;
          }
        });
      }

      const options = {
        type: "video",
        mimeType: navigator.userAgent.toLowerCase().includes("firefox")
          ? "video/webm"
          : "video/webm;codecs=vp9",
        bitsPerSecond: this.videoQuality,
        frameInterval: 30,
        timeSlice: 1000,
      };

      this.recorder = new RecordRTC(this.combinedStream, options);
      this.recorder.startRecording();
      this.isRecording = true;
      this.recordingStartTime = Date.now();
      this.startRecordingTimer();
    },

    async setupMiniPreview() {
      // this.cleanupMiniPreview();

      this.miniPreview = document.createElement("div");
      this.miniPreview.style.cssText = `
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 220px;
        height: 180px;
        background: black;
        border: 2px solid #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        z-index: 9999;
        overflow: hidden;
        cursor: move;
        transition: transform 0.2s ease;
      `;

      try {
        this.miniCameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.miniPreviewVideo = document.createElement("video");
        this.miniPreviewVideo.style.cssText = `
          width: 100%;
          height: 100%;
          object-fit: cover;
        `;
        this.miniPreviewVideo.autoplay = true;
        this.miniPreviewVideo.muted = true;
        this.miniPreviewVideo.playsInline = true;
        this.miniPreviewVideo.srcObject = this.miniCameraStream;

        this.setupDragHandlers();

        this.miniPreview.appendChild(this.miniPreviewVideo);
        document.body.appendChild(this.miniPreview);
      } catch (error) {
        console.error("Error accessing camera:", error);
        this.cleanupMiniPreview();
      }
    },

    setupDragHandlers() {
      this.miniPreview.addEventListener("mousedown", this.handleDragStart);
      this.miniPreview.addEventListener("touchstart", this.handleTouchStart);
      document.addEventListener("mousemove", this.handleDrag);
      document.addEventListener("touchmove", this.handleTouchMove);
      document.addEventListener("mouseup", this.handleDragEnd);
      document.addEventListener("touchend", this.handleDragEnd);
    },

    handleDragStart(e) {
      if (!this.miniPreview) return;

      this.isDragging = true;
      this.dragOffset = {
        x:
          (e.touches ? e.touches[0].clientX : e.clientX) -
          this.miniPreview.offsetLeft,
        y:
          (e.touches ? e.touches[0].clientY : e.clientY) -
          this.miniPreview.offsetTop,
      };

      this.miniPreview.style.transform = "scale(1.05)";
    },

    handleTouchStart(e) {
      e.preventDefault();
      this.handleDragStart(e);
    },

    handleDrag(e) {
      if (!this.isDragging || !this.miniPreview) return;

      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      const x = clientX - this.dragOffset.x;
      const y = clientY - this.dragOffset.y;

      const maxX = window.innerWidth - this.miniPreview.offsetWidth;
      const maxY = window.innerHeight - this.miniPreview.offsetHeight;

      this.miniPreview.style.left = `${Math.max(0, Math.min(x, maxX))}px`;
      this.miniPreview.style.top = `${Math.max(0, Math.min(y, maxY))}px`;
    },

    handleTouchMove(e) {
      e.preventDefault();
      this.handleDrag(e);
    },

    handleDragEnd() {
      this.isDragging = false;
      if (this.miniPreview) {
        this.miniPreview.style.transform = "scale(1)";
      }
    },

    startRecordingTimer() {
      this.recordingTimer = setInterval(() => {
        if (!this.isPaused) {
          const currentTime = Date.now();
          this.totalRecordingTime =
            currentTime - this.recordingStartTime - this.pausedTime;
        }
      }, 1000);
    },

    togglePause() {
      if (this.isPaused) {
        this.recorder.resumeRecording();
        this.pausedTime += Date.now() - this.lastPauseTime;
        this.lastPauseTime = null;
      } else {
        this.recorder.pauseRecording();
        this.lastPauseTime = Date.now();
      }
      this.isPaused = !this.isPaused;
    },

    stopRecording() {
      if (!this.recorder) return;

      clearInterval(this.recordingTimer);
      this.recorder.stopRecording(() => {
        this.recordedVideo = this.recorder.getBlob();
        this.recordedVideoUrl = URL.createObjectURL(this.recordedVideo);
        this.isRecording = false;
        this.isPreviewMode = true;
        this.cleanupRecording();
      });
    },

    cleanupRecording() {
      this.stopAllStreams();
      this.cleanupPreviewElements();
      this.cleanupMiniPreview();
      this.resetRecordingState();
    },

    stopAllStreams() {
      [
        this.cameraStream,
        this.screenStream,
        this.audioStream,
        this.combinedStream,
      ].forEach((stream) => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
            track.enabled = false;
          });
        }
      });

      // Clear stream references
      this.cameraStream = null;
      this.screenStream = null;
      this.audioStream = null;
      this.combinedStream = null;
    },

    cleanupPreviewElements() {
      // Cleanup PiP
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture().catch(console.error);
      }
      if (this.pipVideo) {
        // Clean up will now happen inside `leavepictureinpicture` event
        this.pipVideo = null;
      }
    },
    cleanupMiniPreview() {
      if (this.miniPreview) {
        this.miniPreview.remove();
        this.miniPreview = null;
      }
      this.miniPreviewVideo.srcObject
        .getTracks()
        .forEach((track) => track.stop());
      this.miniPreviewVideo.srcObject = null;
      this.miniCameraStream.getTracks().forEach((track) => track.stop());
      this.miniCameraStream = null;
      console.log("Cleaned up mini preview...");
    },

    removeDragHandlers() {
      if (!this.miniPreview) return;

      this.miniPreview.removeEventListener("mousedown", this.handleDragStart);
      this.miniPreview.removeEventListener("touchstart", this.handleTouchStart);
      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("touchmove", this.handleTouchMove);
      document.removeEventListener("mouseup", this.handleDragEnd);
      document.removeEventListener("touchend", this.handleDragEnd);
    },

    resetRecordingState() {
      this.recordingStartTime = null;
      this.totalRecordingTime = 0;
      this.pausedTime = 0;
      this.lastPauseTime = null;
      this.isPaused = false;
    },

    getResolutionWidth() {
      const resolutions = {
        1000000: 640, // 360p
        2500000: 854, // 480p
        5000000: 1280, // 720p
        8000000: 1920, // 1080p
        16000000: 2560, // 2K
        40000000: 3840, // 4K
      };
      return resolutions[this.videoQuality] || 1280;
    },

    getResolutionHeight() {
      const resolutions = {
        1000000: 360, // 360p
        2500000: 480, // 480p
        5000000: 720, // 720p
        8000000: 1080, // 1080p
        16000000: 1440, // 2K
        40000000: 2160, // 4K
      };
      return resolutions[this.videoQuality] || 720;
    },

    reRecord() {
      this.resetUploadSession();
      this.isPreviewMode = false;
      if (this.recordedVideoUrl) {
        URL.revokeObjectURL(this.recordedVideoUrl);
      }
      this.recordedVideo = null;
      this.recordedVideoUrl = null;
      if (this.$refs.previewVideo) {
        this.$refs.previewVideo.src = "";
      }
    },

    async generateUploadToken() {
      try {
        const response = await this.$axios.get(
          "/v2/recordings/get-upload-token"
        );
        this.uploadToken = response.token;
        console.log(response);
        console.log("Generated upload token...");
      } catch (error) {
        this.toast.staticError(
          "Failed to get upload token. Check your connection."
        );
        throw error; // Prevent upload from starting
      }
    },

    async uploadVideo() {
      // Reset any existing session
      this.resetUploadSession();

      if (this.isUploading) return;

      this.isUploading = true;
      try {
        // Generate token if missing
        if (!this.uploadToken) {
          await this.generateUploadToken();
        }

        this.form.duration = this.totalRecordingTime;
        if (this.form.duration < 60) {
          this.form.duration = 60;
        }

        this.video_name = `video_${Date.now()}_${Math.floor(
          Math.random() * 1000
        )}.webm`;

        this.form.video = new File([this.recorder.getBlob()], this.video_name, {
          type: "video/webm",
        });

        const baseURL =
          process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_PRODUCTION_URL
            : process.env.VUE_APP_STAGING_URL;

        const resumable = new Resumable({
          target: baseURL + "/v2/recordings/upload-video",
          chunkSize: 1 * 1024 * 1024,
          simultaneousUploads: 3,
          testChunks: true,
          query: {
            _token: Math.floor(Math.random() * 1000),
            upload_token: this.uploadToken,
            title: "First Video Upload",
            user_id: "5a8a5d6d-9a28-4621-a35a-95e65d13adf0",
            duration: this.form.duration,
            quality: this.videoQuality,
          },
        });

        // Add the file to upload
        resumable.addFile(this.form.video);

        // Set up event handlers
        resumable.on("fileAdded", (file) => {
          resumable.upload();
          console.log(file);
        });

        resumable.on("fileProgress", (file) => {
          const progress = Math.floor(file.progress() * 100);
          console.log(`Upload progress: ${progress}%`);
          // Update your progress bar here
          this.progressTracker = `Uploading... ${progress}%`;
        });

        resumable.on("fileSuccess", (file, message) => {
          this.handleSuccess("Video uploaded successfully!");
          this.resetUploadSession();
          this.reRecord();
          console.log(file);
          console.log(message);
        });

        resumable.on("fileError", (file, message) => {
          console.error("Upload error:", message);
          this.resetUploadSession();
          this.toast.staticError("Upload failed. Please try again.");
          console.log(file);
        });
      } finally {
        this.isUploading = false;
      }
    },

    resetUploadSession() {
      // Cancel any ongoing upload
      if (this.resumable) {
        this.resumable.cancel();
      }

      // Reset token and state
      this.uploadToken = null;
      this.isUploading = false;

      // Optionally clear any file references
      this.recordedVideo = null;
    },

    handleSuccess(message) {
      this.toast.sendSuccess({
        message: message,
      });
    },

    handleBeforeUnload(event) {
      if (this.isRecording) {
        event.preventDefault();
        event.returnValue =
          "You have an active recording. Are you sure you want to leave?";
        return event.returnValue;
      }
    },
  },
  beforeDestroy() {
    this.resetUploadSession();
    this.cleanupRecording();
    clearInterval(this.recordingTimer);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>

<style lang="scss" scoped>
.recording-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.custom-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.recording-title {
  font-size: 24px !important;
  color: #333;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-bottom: 1.5rem;
}

.camera-preview {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  aspect-ratio: 16 / 9;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1); /* Mirror effect for camera preview */
  }
}

.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.recording-timer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 0.5rem;
}

.countdown-card {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  .display-4 {
    color: white;
    font-weight: bold;
  }
}

.preview {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  video {
    background-color: #000;
  }
}

.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .custom-card {
    margin: 0.5rem;
  }

  .camera-preview {
    width: 95%;
  }

  .recording-controls {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
