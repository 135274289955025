<!-- ~/src/components/CountdownOverlay.vue -->
<template>
  <v-overlay v-model="isCountingDown">
    <div style="position: relative">
      <div style="position: absolute; top: -40vh; transform: translate(-50%)">
        <h1 style="font-size: 300px">
          {{ countdownValue }}
        </h1>
      </div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    isCountingDown: Boolean,
    countdownValue: Number,
  },
};
</script>
