<template>
  <div class="recording-controls">
    <v-btn @click="togglePause" color="primary" class="mr-2">
      {{ isPaused ? "Resume" : "Pause" }}
    </v-btn>
    <v-btn @click="stopRecording" color="error"> Stop Recording </v-btn>
    <div class="recording-timer">{{ formattedRecordingTime }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isPaused: Boolean,
    formattedRecordingTime: String,
  },
  methods: {
    togglePause() {
      this.$emit("toggle-pause");
    },
    stopRecording() {
      this.$emit("stop-recording");
    },
  },
};
</script>

<style scoped>
.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.recording-timer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 0.5rem;
}
</style>
